import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pips from "../../assets/pips35.png";
import RyanRowstonPP from "../../assets/profile-ryan.jpg";

class RyanRowston extends React.Component {
  render() {
    return (
      <div className="faded">
        <div className="profile">
          <img src={RyanRowstonPP} alt="Ryan Rowston" width="70%" />
        </div>
        <div className="bio">
          <h2>Ryan Rowston</h2>
          <h3>Software & Solutions</h3>
          <img src={Pips} alt="StarTrek Pips" />
          <p>
            {
              "A dedicated solutions developer, Ryan specialises in web application and API development, with specific experience in information management and system integration. He has several years of commercial experience in delivering successful IT and software projects and takes pride in projects that improve people's lives and productivity."
            }
          </p>
          <a href="https://www.linkedin.com/in/ryanrowston/" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" color="#8f00dd" />
          </a>
        </div>
      </div>
    );
  }
}

export default RyanRowston;
