import PropTypes from "prop-types";
import React from "react";
import "./Button.css";

// stateless functional component
export const Button = ({ name, onClick }) => <a onClick={onClick}>{name}</a>;

// set the component 'prop' types
Button.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func
};

// export component
export default Button;
