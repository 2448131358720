import React from "react";
import PropTypes from "prop-types";

class ProgAdvis extends React.Component {
  render() {
    return (
      <div className="faded">
        <h3>PROGRAM ADVISORY</h3>
        <p>We help businesses become self-sufficient with graduate intakes, and the tertiary sector to develop programs that increase student employability.</p>
        <p>This is perfect for:</p>
        <ul>
          <li>Businesses wanting to go it alone with their own graduate programs.</li>
          <li>Businesses wanting to review the effectiveness of their existing graduate programs.</li>
          <li>Tertiary institutions wanting commercial advice on programs which increase employability.</li>
        </ul>
        <p id="desktop">
          Interested? <a href="#contact">Contact us</a> if you’d like to know more or continue to{" "}
          <a href="mailto:hello@hfoh.com.au?subject=Hey, Let's have a coffee!">ask us about becoming a mentor</a> yourself.
        </p>
        <div className="buttons">
          <button onClick={this.props.onBackClick} className="btn back" type="button">
            Back
          </button>
        </div>
      </div>
    );
  }
}

ProgAdvis.propTypes = {
  onBackClick: PropTypes.func
};

export default ProgAdvis;
