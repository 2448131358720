import React from "react";
import Pips from "../../assets/pips1.png";
import IsabellaScalziPP from "../../assets/profile-isabella.jpg";

class IsabellaScalzi extends React.Component {
  render() {
    return (
      <div className="faded">
        <div className="profile">
          <img src={IsabellaScalziPP} alt="Isabella Scalzi" width="70%" />
        </div>
        <div className="bio">
          <h2>Isabella Scalzi</h2>
          <h3>Pen Tester & Software Developer</h3>
          <img src={Pips} alt="StarTrek Pips" />
          <p>
            Isabella is a graduate full-stack developer with a keen interest in cybersecurity and secure programming. Isabella strives to create software that is secure and meets the needs of clients.
            She brings strong attention to detail and problem-solving skills to her work and enjoys all aspects of the software development process from developing new features to documentation and
            bug fixing.
          </p>
        </div>
      </div>
    );
  }
}

export default IsabellaScalzi;
