import React from "react";
import "../Geekify.css";

class GeekWhatWeKnow extends React.Component {
  render() {
    return (
      <div className="faded" id="geek">
        <div className="desktop">
          <div className="moderate leftpad">
            <h3>CLOUD</h3>
            <ul>
              <li>IaaS/PaaS: Azure, Amazon Web Services</li>
              <li>SaaS: Xero, Office 365, Time sheeting, Tasks & projects, Sales pipelines, ...</li>
              <li>DevOps: Jira, Azure DevOps, Automation, PowerShell, AWS Command Line Tooling</li>
            </ul>
            <h3>INTEGRATION</h3>
            <ul>
              <li>Cloud: Microservices architecture, Logic apps, Message queues</li>
              <li>ETL: Lambda, SSIS, Web jobs, Data transformation & synchronisation</li>
              <li>SaaS: Cross platform workflow and coordination</li>
            </ul>
            <h3>SOFTWARE</h3>
            <ul>
              <li>Foundation: HTML5, CSS3, JavaScript, .NET, SQL, NoSQL</li>
              <li>Framework: JQuery, NodeJS, Angular, React, ASP.NET Core, MVC</li>
              <li>Solution: Bespoke solutions on IaaS and PaaS</li>
            </ul>
          </div>
          <div className="moderate right">
            <h3>DATA AND ANALYTICS</h3>
            <ul>
              <li>SQL: SQL Server, MySQL</li>
              <li>No SQL: MongoDB, Blobs</li>
              <li>Analytics: D3, Redshift, SaaS, PowerBI, bespoke</li>
            </ul>
            <h3>SECURITY</h3>
            <ul>
              <li>Operation: Patterns & practices, defensive design and configuration</li>
              <li>SDLC: Test automation, test patterning, configuration automation</li>
              <li>Verification: Injection, Auth, XSS, configuration, Access Control, CSRF, ...</li>
            </ul>
            <h3>REALITY 2.0</h3>
            <ul>
              <li>Experiences: 3D, Reality augmentation, IoT human interaction</li>
              <li>Design: Pervasive design, traditional software augmentation</li>
              <li>Outcomes: Skill development, information dissemination, reality tagging</li>
            </ul>
          </div>
        </div>
        <div className="mobile">
          <div className="moderate mobile">
            <h3>CLOUD</h3>
            <ul>
              <li>IaaS/PaaS: Azure, Amazon Web Services</li>
              <li>SaaS: Xero, Office 365, Time sheeting, Tasks & projects, Sales pipelines, ...</li>
              <li>DevOps: Jira, Azure DevOps, Automation, PowerShell, AWS Command Line Tooling</li>
            </ul>
            <h3>INTEGRATION</h3>
            <ul>
              <li>Cloud: Microservices architecture, Logic apps, Message queues</li>
              <li>ETL: Lambda, SSIS, Web jobs, Data transformation & synchronisation</li>
              <li>SaaS: Cross platform workflow and coordination</li>
            </ul>
            <h3>SOFTWARE</h3>
            <ul>
              <li>Foundation: HTML5, CSS3, JavaScript, .NET, SQL, NoSQL</li>
              <li>Framework: JQuery, NodeJS, Angular, React, ASP.NET Core, MVC</li>
              <li>Solution: Bespoke solutions on IaaS and PaaS</li>
            </ul>
            <h3>DATA AND ANALYTICS</h3>
            <ul>
              <li>SQL: SQL Server, MySQL</li>
              <li>No SQL: MongoDB, Blobs</li>
              <li>Analytics: D3, Redshift, SaaS, PowerBI, bespoke</li>
            </ul>
            <h3>SECURITY</h3>
            <ul>
              <li>Operation: Patterns & practices, defensive design and configuration</li>
              <li>SDLC: Test automation, test patterning, configuration automation</li>
              <li>Verification: Injection, Auth, XSS, configuration, Access Control, CSRF, ...</li>
            </ul>
            <h3>REALITY 2.0</h3>
            <ul>
              <li>Experiences: 3D, Reality augmentation, IoT human interaction</li>
              <li>Design: Pervasive design, traditional software augmentation</li>
              <li>Outcomes: Skill development, information dissemination, reality tagging</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default GeekWhatWeKnow;
