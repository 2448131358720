import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Footer extends React.Component {
  render() {
    return (
      <div className="bar">
        <div className="footer">
          <div className="social">
            <a href="https://www.linkedin.com/company/head-full-of-heart" alt="Head Full of Heart Linkedin" rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" color="var(--color-brand-charcoal)" />
            </a>
            <a href="mailto:hello@hfoh.com.au?subject=I'd like to know more about Head Full of Heart" alt="hello@hfoh.com.au">
              <FontAwesomeIcon icon="paper-plane" size="2x" color="var(--color-brand-charcoal)" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
