import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pips from "../../assets/pips4.png";
import LucaGnezdaPP from "../../assets/profile-luca.png";

class LucaGnezda extends React.Component {
  render() {
    return (
      <div className="faded">
        <div className="profile">
          <img src={LucaGnezdaPP} alt="Luca Gnezda" width="70%" />
        </div>
        <div className="bio">
          <h2>Luca Gnezda</h2>
          <h3>Digital Strategy & Architecture</h3>
          <img src={Pips} alt="StarTrek Pips" />
          <p>
            A passionate IT strategist, solution architect, and agile project lead with 20+ years of experience, Luca’s background is ideal for CTO and IT leadership level advisory, as well as
            strategic projects centred around commercialisation, innovation, transformation and digital disruption.
          </p>
          <a href="https://www.linkedin.com/in/lucagnezda/" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" color="#8f00dd" />
          </a>
        </div>
      </div>
    );
  }
}

export default LucaGnezda;
