import React from "react";
import "./Services.css";
import MenDev from "./MenDev";
import Offer from "./Offer";
import ManGradProg from "./ManGradProg";
import ProgAdvis from "./ProgAdvis";
import GradSecond from "./GradSecond";
import GradOn from "./GradsOn";
import SupStuPlace from "./SupStuPlace";
import Collapsible from "react-collapsible";

class Services extends React.Component {
  constructor() {
    super();
    this.state = {
      content: <Offer onServicesClick={() => this.handleClick("MenDev")} />
    };
  }

  handleClick(contentType) {
    switch (contentType) {
      case "MenDev":
        this.setState({ content: <MenDev onServicesClick={() => this.handleClick("SupStuPlace")} onBackClick={() => this.handleClick("Offer")} /> });
        break;
      case "ManGradProg":
        this.setState({ content: <ManGradProg onServicesClick={() => this.handleClick("ProgAdvis")} onBackClick={() => this.handleClick("Offer")} /> });
        break;
      case "ProgAdvis":
        this.setState({ content: <ProgAdvis onServicesClick={() => this.handleClick("MenDev")} onBackClick={() => this.handleClick("Offer")} /> });
        break;
      case "GradSecond":
        this.setState({ content: <GradSecond onServicesClick={() => this.handleClick("ManGradProg")} onBackClick={() => this.handleClick("Offer")} /> });
        break;
      case "GradOn":
        this.setState({ content: <GradOn onServicesClick={() => this.handleClick("GradSecond")} onBackClick={() => this.handleClick("Offer")} /> });
        break;
      case "SupStuPlace":
        this.setState({ content: <SupStuPlace onServicesClick={() => this.handleClick("GradOn")} onBackClick={() => this.handleClick("Offer")} /> });
        break;
      case "Offer":
        this.setState({ content: <Offer onServicesClick={() => this.handleClick("MenDev")} /> });
        document.getElementById("clientlist").className = "hide";
        document.getElementById("ProgAdvis").className = "hexPur";
        document.getElementById("ManGradProg").className = "hexPur";
        document.getElementById("GradSecond").className = "hexPur";
        document.getElementById("GradOn").className = "hexPur";
        document.getElementById("SupStuPlace").className = "hexPur";
        document.getElementById("MenDev").className = "hexPur";
        document.getElementById("btnedu").className = "btn education";
        document.getElementById("btnbus").className = "btn business";
        document.getElementById("btnstu").className = "btn students";
        break;
      default:
        this.setState({ content: <Offer onServicesClick={() => this.handleClick("MenDev")} /> });
        break;
    }
  }

  render() {
    return (
      <div className="services">
        <div className="title">
          <a className="desktop" onClick={() => this.handleClick("Offer")}>
            {" "}
            <h1>SERVICES</h1>
          </a>
          <h1 className="mobile">SERVICES</h1>
        </div>
        <div className="main">
          <div className="desktop">
            <div className="moderate left desktop">
              <h2>WHAT WE OFFER</h2>
              {this.state.content}
            </div>
            <div className="moderate right desktop">
              <ul id="hexGrid">
                <li className="hex">
                  <div className="hexIn" />
                </li>
                <li className="hex">
                  <div id="MenDev" className="hexPur">
                    <a onClick={() => this.handleClick("MenDev")} className="hexLink">
                      <h3>
                        Mentoring <br /> and <br /> Development
                      </h3>
                    </a>
                  </div>
                </li>
                <li className="hex">
                  <div id="SupStuPlace" className="hexPur">
                    <a onClick={() => this.handleClick("SupStuPlace")} className="hexLink">
                      <h3>Supported Student Placements</h3>
                    </a>
                  </div>
                </li>
                <li className="hex">
                  <div className="hexIn" />
                </li>
                <li className="hex">
                  <div id="ProgAdvis" className="hexPur">
                    <a onClick={() => this.handleClick("ProgAdvis")} className="hexLink">
                      <h3>Program Advisory</h3>
                    </a>
                  </div>
                </li>
                <li className="hex">
                  <div id="Offer" className="hexPur hexTrans">
                    <a onClick={() => this.handleClick("Offer")} className="hexLink">
                      <h3>
                        WHAT <br /> WE <br /> OFFER
                      </h3>
                    </a>
                  </div>
                </li>
                <li className="hex">
                  <div id="GradOn" className="hexPur">
                    <a onClick={() => this.handleClick("GradOn")} className="hexLink">
                      <h3>
                        Graduates <br /> On-Demand
                      </h3>
                    </a>
                  </div>
                </li>
                <li className="hex">
                  <div className="hexIn" />
                </li>
                <li className="hex">
                  <div id="ManGradProg" className="hexPur">
                    <a onClick={() => this.handleClick("ManGradProg")} className="hexLink">
                      <h3>Managed Graduate Program</h3>
                    </a>
                  </div>
                </li>
                <li className="hex">
                  <div id="GradSecond" className="hexPur">
                    <a onClick={() => this.handleClick("GradSecond")} className="hexLink">
                      <h3>Graduates Secondments</h3>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="mobile">
            <Offer />
            <Collapsible trigger="MENTORING AND DEVELOPMENT">
              <MenDev />
            </Collapsible>
            <Collapsible trigger="SUPPORTED STUDENT PLACEMENTS">
              <SupStuPlace />
            </Collapsible>
            <Collapsible trigger="GRADUATES ON-DEMAND">
              <GradOn />
            </Collapsible>
            <Collapsible trigger="GRADUATES SECONDMENTS">
              <GradSecond />
            </Collapsible>
            <Collapsible trigger="MANAGED GRADUATE PROGRAM">
              <ManGradProg />
            </Collapsible>
            <Collapsible trigger="PROGRAM ADVISORY">
              <ProgAdvis />
            </Collapsible>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
