import React from "react";
import PropTypes from "prop-types";

class MenDev extends React.Component {
  render() {
    return (
      <div className="faded">
        <h3>MENTORING AND DEVELOPMENT</h3>
        <p>
          We help students to grow, prepare for employment, and understand employers. This is a free service to students, available as our capacity allows. We also take every opportunity to volunteer
          as guest lecturers for our local tertiary sector institutions.
        </p>
        <p>Why do we do it? It costs us little and delivers so much, giving insight and confidence to students, whilst helping them build their initial industry network.</p>
        <p id="desktop">
          Interested? <a href="#contact">Contact us</a> if you’d like to know more, or continue to <a onClick={this.props.onServicesClick}>Supported Student Placements.</a>
        </p>
        <div className="buttons">
          <button onClick={this.props.onBackClick} className="btn back" type="button">
            Back
          </button>
        </div>
      </div>
    );
  }
}

MenDev.propTypes = {
  onBackClick: PropTypes.func,
  onServicesClick: PropTypes.func
};

export default MenDev;
