import React from "react";
import "./Home.css";
import logofull from "../../assets/hfoh_logofull.png";
import Testimonals from "./Testimonals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <div>
          <div className="home mainlogo">
            <img src={logofull} alt="Head Full of Heart - Logo" />
          </div>
          <div className="moderate center fittomobile">
            <div className="testi">
              <Testimonals />
            </div>
          </div>
          <div className="scroll center">
            <a href="#about">
              <FontAwesomeIcon icon="chevron-down" size="3x" color="var(--color-brand-charcoal)" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
