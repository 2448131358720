import React from "react";

class HowWeDoIt extends React.Component {
  render() {
    return (
      <div>
        <div className="about--info faded">
          <h2>HOW WE DO IT</h2>
          <h3>ALTRUISTIC GOALS</h3>
          <p>
            As business owners, we’ve seen your challenges first hand and we’re committed to fostering strong relationships between graduates and business. That’s why we donate time as tertiary
            mentors, run guest lectures, and support the education sector more broadly.
          </p>
          <h3>TALENTED YOUNG PROFESSIONALS</h3>
          <p>We hand pick our graduates based on their drive, determination and ability; so they’ll love working with you and be great at it.</p>
          <h3>ON DEMAND</h3>
          <p>Our on-demand service can be scaled up and down to match the ebb and flow of your business.</p>
          <h3>OR AS PART OF YOUR BUSINESS</h3>
          <p>
            By leaning on our services, a business of any size can run a successful graduate program. You gain access to talented young people, without the operational scaffolding that normally comes
            with it.
          </p>
          <h3>SUPPORTED BY OUR PROGRAM</h3>
          <p>
            At its heart, our business is one large graduate program. We place our young professionals under the wing of local tech and industry leaders, providing guidance and professional
            development, so your business doesn’t have to.
          </p>
        </div>
      </div>
    );
  }
}

export default HowWeDoIt;
