import React from "react";
import PropTypes from "prop-types";

class GradsOn extends React.Component {
  render() {
    return (
      <div className="faded">
        <h3>GRADUATES ON-DEMAND</h3>
        <p>We help businesses access young professionals flexibly and with low risk.</p>
        <p>This is perfect for:</p>
        <ul>
          <li>Start-ups and early-stage commercialisation businesses, with big ideas and highly-variable workforce needs.</li>
          <li>Businesses wanting to try the service without a long-term commitment.</li>
          <li>Businesses undertaking a project and needing burst resources to supplement an experienced workforce.</li>
        </ul>
        <p id="desktop">
          Interested? <a href="#contact">Contact us</a> if you’d like to know more or continue to <a onClick={this.props.onServicesClick}>Graduates Secondments.</a>
        </p>
        <div className="buttons">
          <button onClick={this.props.onBackClick} className="btn back" type="button">
            Back
          </button>
        </div>
      </div>
    );
  }
}

GradsOn.propTypes = {
  onBackClick: PropTypes.func,
  onServicesClick: PropTypes.func
};

export default GradsOn;
