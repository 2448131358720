import React from "react";

class WhyOurBusiness extends React.Component {
  render() {
    return (
      <div>
        <div className="about--info faded">
          <h2>WHY OUR BUSINESS</h2>
          <p>We do one thing better than anyone else: We make it as easy as possible for you to engage young tech professionals, blending them into your organisation as and when you need them.</p>
          <p>
            Our business model is simple. We offer young passionate tech professionals on demand, on secondment, or in support of your staffing intakes. We do this at close to not-for-profit levels,
            so Adelaide as a whole can benefit.
          </p>
          <p>
            Essentially, we’re paying it forward to build a positive cycle for the future. This makes us a socially responsible and local alternative to off-shoring, and a lower cost alternative to
            consulting.
          </p>
          <p>Just describe your needs to us and we’ll take care of the rest.</p>
        </div>
      </div>
    );
  }
}

export default WhyOurBusiness;
