import React from "react";
import "./ContentWindow.css";
import PropTypes from "prop-types";

class Content extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="content">{this.props.children}</div>
      </div>
    );
  }
}

Content.propTypes = {
  children: PropTypes.any
};

export default Content;
