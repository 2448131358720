import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pips from "../../assets/pips3.png";
import ClaireAmorHaynesPP from "../../assets/profile-claire.jpg";

class ClaireAmorHaynes extends React.Component {
  render() {
    return (
      <div className="faded">
        <div className="profile">
          <img src={ClaireAmorHaynesPP} alt="Claire Amor-Haynes" width="70%" />
        </div>
        <div className="bio">
          <h2>Claire Amor-Haynes</h2>
          <h3>Marketing & Customer Engagement </h3>
          <img src={Pips} alt="StarTrek Pips" />
          <p>
            Claire brings extensive experience across Marketing, Project Management, Event Management and Business Development. Claire thrives on building authentic connections and creating memorable
            experiences for clients and stakeholders.{" "}
          </p>
          <a href="https://au.linkedin.com/in/claire-amor-haynes-93693a61" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" color="#8f00dd" />
          </a>
        </div>
      </div>
    );
  }
}

export default ClaireAmorHaynes;
