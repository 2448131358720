import React from "react";

class TertiaryClients extends React.Component {
  render() {
    return (
      <div className="faded clients tertiaryclients">
        <h3>Some of our clients</h3>
          <div className="moderate left">
            <li>Flinders University</li>
            <li>TAFE SA</li>
            <li>University of Adelaide</li>
            <li>University of South Australia</li>
          </div>
      </div>
    );
  }
}
export default TertiaryClients;
