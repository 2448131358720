import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pips from "../../assets/pips35.png";
import JakobPenningtonPP from "../../assets/profile-jakob.png";

class JakobPennington extends React.Component {
  render() {
    return (
      <div className="faded">
        <div className="profile">
          <picture>
            <img src={JakobPenningtonPP} alt="Jakob Pennington" width="70%" />
          </picture>
        </div>
        <div className="bio">
          <h2>Jakob Pennington</h2>
          <h3>Application Security</h3>
          <img src={Pips} alt="StarTrek Pips" />
          <p>
            {"Drawing on 7+ years of experience in software development and cybersecurity, Jakob specialises in designing, building, testing and operating secure software products. If there is one thing Jakob enjoys more than learning, it's teaching what he has learned to his peers, clients and students."}
          </p>
          <a href="https://www.linkedin.com/in/jakobpennington/" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" color="#8f00dd" />
          </a>
        </div>
      </div>
    );
  }
}

export default JakobPennington;
