import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pips from "../../assets/pips3.png";
import ToreighTillerPP from "../../assets/profile-toreigh.png";

class ToreighTiller extends React.Component {
  render() {
    return (
      <div className="faded">
        <div className="profile">
          <img src={ToreighTillerPP} alt="Toreigh Tiller" width="70%" />
        </div>
        <div className="bio">
          <h2>Toreigh Tiller</h2>
          <h3>Product Design</h3>
          <img src={Pips} alt="StarTrek Pips" />
          <p>
            An enthusiastic product designer, Toreigh specialises in designing and delivering solutions that focus on great user experience. Toreigh has a passion for problem solving and combines her
            creative, technical, and analytical skills to create solutions that balance customer needs, company goals and design cohesion.
          </p>
          <a href="https://www.linkedin.com/in/toreigh-tiller/" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" color="#8f00dd" />
          </a>
        </div>
      </div>
    );
  }
}

export default ToreighTiller;
