import React from "react";

class BusinessClients extends React.Component {
  render() {
    return (
      <div className="faded clients businessclients">
          <h3>Some of our clients</h3>
          <div className="moderate left">
            <li>Australian Legal Practice Management Association</li>
            <li>Code 360</li>
            <li>Corston</li>
            <li>CQR</li>
            <li>Department for Education</li>
            <li>Department for Industry and Skills</li>
            <li>Department of Planning, Transport and Infrastructure</li>
          </div>
          <div className="moderate right">
            <li>Department of Premier and Cabinet</li>
            <li>Jamshop</li>
            <li>Primary Industries and Regions South Australia</li>
            <li>SA Power Networks</li>
            <li>South Australian Government Financing Authority</li>
            <li>South Australian Health and Medical Research Institute</li>
            <li>Those creatives</li>
          </div>
      </div>
    );
  }
}
export default BusinessClients;
