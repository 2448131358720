import React from "react";

class WhyItWorks extends React.Component {
  render() {
    return (
      <div>
        <div className="about--info faded">
          <h2>WHY IT WORKS</h2>
          <h3>TERTIARY RELATIONSHIPS</h3>
          <p>We have close relationships with local tertiary institutions and support their commercial outreach initiatives. This allows us to better connect with the most talented, driven graduates across a range of experiences and skill sets.</p>
          <h3>TAPTU SUPPORT</h3>
          <p>Our young professionals receive ongoing professional skills coaching through our sister brand <a href="https://www.taptu.com.au" rel="noopener noreferrer" target="_blank">Taptu</a>, allowing us to tap into decades of industry experience and knowledge as staff, managers, leaders and business owners.</p>
          <h3>MENTOR NETWORK</h3>
          <p>We have a network of mentors, who are thought leaders in their respective disciplines, gifting their time and experiences to fast-track the skills development of our young professionals.</p>
          <h3>RISK IS ONLY RISKY WHEN NOT MITIGATED</h3>
          <p>By picking a service format that aligns to your needs as a business or young professional, all parties can enjoy the advantages, while being supported by experts who know how to maximise benefits and remove risks.</p>
      </div>
      </div>
    );
  }
}

export default WhyItWorks;
