import React from "react";
import PropTypes from "prop-types";

class GradSeconds extends React.Component {
  render() {
    return (
      <div className="faded">
        <h3>GRADUATES SECONDMENTS</h3>
        <p>We help businesses access young professionals consistently and cost-effectively, working in longer-term positions and supplementing existing staff.</p>
        <p>This is perfect for:</p>
        <ul>
          <li>Small-to-medium business wanting fresh ideas and talent, but without the internal expertise to coach younger technology resources into new skills and roles.</li>
          <li>Extending a team to meet growing demands, but without the need to recruit or increase long-term headcount.</li>
          <li>Businesses targeting digital disruption with new-to-market services.</li>
        </ul>
        <p id="desktop">
          Interested? <a href="#contact">Contact us</a> if you’d like to know more or continue to <a onClick={this.props.onServicesClick}>Managed Graduate Program.</a>
        </p>
        <div className="buttons">
          <button onClick={this.props.onBackClick} className="btn back" type="button">
            Back
          </button>
        </div>
      </div>
    );
  }
}

GradSeconds.propTypes = {
  onBackClick: PropTypes.func,
  onServicesClick: PropTypes.func
};

export default GradSeconds;
