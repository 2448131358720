import React from "react";
import PropTypes from "prop-types";

class ManGradProg extends React.Component {
  render() {
    return (
      <div className="faded">
        <h3>MANAGED GRADUATE PROGRAM</h3>
        <p>We help businesses by supporting recruitment of their own young professionals and providing graduate program services on their behalf.</p>
        <p>This is perfect for:</p>
        <ul>
          <li>Businesses establishing a new office, but without the local HR or tech-team footprint to effectively coach and mentor this new talent.</li>
          <li>Businesses wanting to transition from secondment placements to their own graduates.</li>
        </ul>
        <p id="desktop">
          Interested? <a href="#contact">Contact us</a> if you’d like to know more or continue to <a onClick={this.props.onServicesClick}>Program Advisory.</a>
        </p>
        <div className="buttons">
          <button onClick={this.props.onBackClick} className="btn back" type="button">
            Back
          </button>
        </div>
      </div>
    );
  }
}

ManGradProg.propTypes = {
  onBackClick: PropTypes.func,
  onServicesClick: PropTypes.func
};

export default ManGradProg;
