import { default as React } from "react";
import { hydrate, render } from "react-dom";
import HfohWebsiteApp from "./hfoh/HfohWebsiteApp";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
import TaptuWebsiteApp from "./taptu/TaptuWebsiteApp";

// render app
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  // if we're prerendering, then hydrate the app
  // taptu website
  if (process.env.REACT_APP_WEBSITE === "taptu") hydrate(<TaptuWebsiteApp />, rootElement);
  // hfoh website
  else hydrate(<HfohWebsiteApp />, rootElement);
  // register service worker
  registerServiceWorker();
} else {
  // otherwise, usually render the react app
  // taptu website
  if (process.env.REACT_APP_WEBSITE === "taptu") render(<TaptuWebsiteApp />, rootElement);
  // hfoh website
  else render(<HfohWebsiteApp />, rootElement);
}
