import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pips from "../../assets/pips3.png";
import AlexandraJamesPP from "../../assets/profile-alex.jpg";

class AlexandraJames extends React.Component {
  render() {
    return (
      <div className="faded">
        <div className="profile">
          <img src={AlexandraJamesPP} alt="Alexandra James" width="70%" />
        </div>
        <div className="bio">
          <h2>Alexandra James</h2>
          <h3>Software & Solutions</h3>
          <img src={Pips} alt="StarTrek Pips" />
          <p>
            {
              "Alexandra is an experienced software and solutions specialist who thrives on understanding clients' needs, driving her implementation of positive solutions. Working for prominent consulting organisations has provided Alexandra with a diverse skillset in both software development and application support in a variety of sectors. Alexandra’s passion for mentoring reflects her commitment to growing humanistic connections in the IT industry."
            }
          </p>
          <a href="https://www.linkedin.com/in/alexandra-james-749853136" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" color="#8f00dd" />
          </a>
        </div>
      </div>
    );
  }
}

export default AlexandraJames;
