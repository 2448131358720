import React from "react";
import "./Team.css";

import AlexandraJamesBio from "./AlexandraJames";
import ClaireAmorHaynesBio from "./ClaireAmorHaynes";
import DaisyKeatingBio from "./DaisyKeating";
import LucaGnezdaBio from "./LucaGnezda";
import JakobPenningtonBio from "./JakobPennington";
import RyanRowstonBio from "./RyanRowston";
import SolomonLaingBio from "./SolomonLaing";
import ScottHartBio from "./ScottHart";
import ToreighTillerBio from "./ToreighTiller";
import IsabellaScalziBio from "./IsabellaScalzi";

import AlexandraJamesPP from "../../assets/profile-alex.jpg";
import ClaireAmorHaynesPP from "../../assets/profile-claire.jpg";
import DaisyKeatingPP from "../../assets/profile-daisy.jpg";
import LucaGnezdaPP from "../../assets/profile-luca.png";
import JakobPenningtonPP from "../../assets/profile-jakob.png";
import UserGrad from "../../assets/user-graduate-solid.png";
import RyanRowstonPP from "../../assets/profile-ryan.jpg";
import SolomonLaingPP from "../../assets/profile-solomon.jpg";
import ScottHartPP from "../../assets/profile-scott.png";
import ToreighTillerPP from "../../assets/profile-toreigh.png";
import IsabellaScalziPP from "../../assets/profile-isabella.jpg";

import Collapsible from "react-collapsible";

class Team extends React.Component {
  constructor() {
    super();
    this.state = {
      content: <IsabellaScalziBio />,
      youngprofessionals: "#",
      mentors: "#",
      error: null
    };
  }

  componentDidMount() {
    fetch("./counter.json")
      .then(function(response) {
        return response.json();
      })
      .then(data => this.setState({ youngprofessionals: data.youngpros, mentors: data.mentors }))
      .catch(error => this.setState({ error }));
  }

  handleClick(contentType) {
    switch (contentType) {
      case "AlexandraJames":
        this.setState({ content: <AlexandraJamesBio /> });
        break;
      case "ClaireAmorHaynes":
        this.setState({ content: <ClaireAmorHaynesBio /> });
        break;
      case "DaisyKeating":
        this.setState({ content: <DaisyKeatingBio /> });
        break;
      case "LucaGnezda":
        this.setState({ content: <LucaGnezdaBio /> });
        break;
      case "JakobPennington":
        this.setState({ content: <JakobPenningtonBio /> });
        break;
      case "RyanRowston":
        this.setState({ content: <RyanRowstonBio /> });
        break;
      case "SolomonLaing":
        this.setState({ content: <SolomonLaingBio /> });
        break;
      case "ScottHart":
        this.setState({ content: <ScottHartBio /> });
        break;
      case "ToreighTiller":
        this.setState({ content: <ToreighTillerBio /> });
        break;
      case "IsabellaScalzi":
        this.setState({ content: <IsabellaScalziBio /> });
        break;
    }
  }

  render() {
    return (
      <div>
        <div className="team">
          <div className="title">
            <h1>TEAM</h1>
          </div>
          <div className="main">
            <p>
              Meet some of our young professionals and mentors, or <a href="#contact"> ask </a> about the rest of our team
            </p>
            <div className="desktop">
              <div className="moderate left desktop">
                <h2>YOUNG PROFESSIONALS</h2>
                <ul id="hexGridTeam">
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a onClick={() => this.handleClick("IsabellaScalzi")} className="hexLinkTeam">
                        <img src={IsabellaScalziPP} alt="" />
                        <h2 className="hextop">Isabella</h2>
                        <h2 className="hexbottom">Scalzi</h2>
                      </a>
                    </div>
                  </li>
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a href="mailto:hello@hfoh.com.au?subject=I'd like to know more about your team" className="hexLinkTeam hexCharcoal">
                        <img src={UserGrad} alt="" />
                        <h2 className="hextop">Ask about</h2>
                        <h2 className="hexbottom">our full team</h2>
                      </a>
                    </div>
                  </li>
                </ul>
                <h3 className="youngprofss">With a further {this.state.youngprofessionals} young professional lives changed</h3>
                <h2>MENTORS</h2>
                <ul id="hexGridTeam">
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a onClick={() => this.handleClick("AlexandraJames")} className="hexLinkTeam">
                        <img src={AlexandraJamesPP} alt="" />
                        <h2 className="hextop">Alexandra</h2>
                        <h2 className="hexbottom">James</h2>
                      </a>
                    </div>
                  </li>
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a onClick={() => this.handleClick("ClaireAmorHaynes")} className="hexLinkTeam">
                        <img src={ClaireAmorHaynesPP} alt="" />
                        <h2 className="hextop">Claire</h2>
                        <h2 className="hexbottom">Amor-Haynes</h2>
                      </a>
                    </div>
                  </li>
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a onClick={() => this.handleClick("DaisyKeating")} className="hexLinkTeam">
                        <img src={DaisyKeatingPP} alt="" />
                        <h2 className="hextop">Daisy</h2>
                        <h2 className="hexbottom">Keating</h2>
                      </a>
                    </div>
                  </li>
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a onClick={() => this.handleClick("JakobPennington")} className="hexLinkTeam">
                        <img src={JakobPenningtonPP} alt="" />
                        <h2 className="hextop">Jakob</h2>
                        <h2 className="hexbottom">Pennington</h2>
                      </a>
                    </div>
                  </li>
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a onClick={() => this.handleClick("LucaGnezda")} className="hexLinkTeam">
                        <img src={LucaGnezdaPP} alt="" />
                        <h2 className="hextop">Luca</h2>
                        <h2 className="hexbottom">Gnezda</h2>
                      </a>
                    </div>
                  </li>
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a onClick={() => this.handleClick("RyanRowston")} className="hexLinkTeam">
                        <img src={RyanRowstonPP} alt="" />
                        <h2 className="hextop">Ryan</h2>
                        <h2 className="hexbottom">Rowston</h2>
                      </a>
                    </div>
                  </li>
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a onClick={() => this.handleClick("ScottHart")} className="hexLinkTeam">
                        <img src={ScottHartPP} alt="" />
                        <h2 className="hextop">Scott</h2>
                        <h2 className="hexbottom">Hart</h2>
                      </a>
                    </div>
                  </li>
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a onClick={() => this.handleClick("SolomonLaing")} className="hexLinkTeam">
                        <img src={SolomonLaingPP} alt="" />
                        <h2 className="hextop">Solomon</h2>
                        <h2 className="hexbottom">Laing</h2>
                      </a>
                    </div>
                  </li>
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a onClick={() => this.handleClick("ToreighTiller")} className="hexLinkTeam">
                        <img src={ToreighTillerPP} alt="" />
                        <h2 className="hextop">Toreigh</h2>
                        <h2 className="hexbottom">Tiller</h2>
                      </a>
                    </div>
                  </li>
                  <li className="hexTeam">
                    <div className="hexInTeam">
                      <a href="mailto:hello@hfoh.com.au?subject=I'd like to know more about your mentors" className="hexLinkTeam hexCharcoal">
                        <img src={UserGrad} alt="" />
                        <h2 className="hextop">Ask about</h2>
                        <h2 className="hexbottom">mentoring</h2>
                      </a>
                    </div>
                  </li>
                </ul>
                <h3 className="businesstrans">With participation from an additional {this.state.mentors} local industry mentors</h3>
              </div>
              <div className="narrow right desktop">{this.state.content}</div>
            </div>
            <div className="mobile">
              <h2>YOUNG PROFESSIONALS</h2>
              <Collapsible trigger="Isabella Scalzi">
                <IsabellaScalziBio />
              </Collapsible>
              
              <h2>MENTORS</h2>
              <Collapsible trigger="Alexandra James">
                <AlexandraJamesBio />
              </Collapsible>
              <Collapsible trigger="Claire Amor-Haynes">
                <ClaireAmorHaynesBio />
              </Collapsible>
              <Collapsible trigger="Daisy Keating">
                <DaisyKeatingBio />
              </Collapsible>
              <Collapsible trigger="Jakob Pennington">
                <JakobPenningtonBio />
              </Collapsible>
              <Collapsible trigger="Luca Gnezda">
                <LucaGnezdaBio />
              </Collapsible>
              <Collapsible trigger="Ryan Rowston">
                <RyanRowstonBio />
              </Collapsible>
              <Collapsible trigger="Scott Hart">
                <ScottHartBio />
              </Collapsible>              
              <Collapsible trigger="Solomon Laing">
                <SolomonLaingBio />
              </Collapsible>
              <Collapsible trigger="Toreigh Tiller">
                <ToreighTillerBio />
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
