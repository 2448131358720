import React from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import "../HfohWebsiteApp.css";

import Home from "./Home/Home";
import About from "./About/About";
import Services from "./Services/Services";
import Contact from "./Contact/Contact";
import Team from "./Team/Team";

export const FullpageWrapper = fullpageprops => (
  <ReactFullpage
    {...fullpageprops}
    render={() => {
      return (
        <div id="fullpage-wrapper">
          <div className="section" id="section-0" data-anchor="home">
            <Home />
          </div>
          <div className="section" id="section-1" data-anchor="about">
            <About />
          </div>
          <div className="section" id="section-2" data-anchor="services">
            <Services />
          </div>
          <div className="section" id="section-3" data-anchor="team">
            <Team />
          </div>
          <div className="section" id="section-4" data-anchor="contact">
            <Contact />
          </div>
        </div>
      );
    }}
  />
);

export default FullpageWrapper;
