import React from "react";
import PropTypes from "prop-types";

class SupStuPlace extends React.Component {
  render() {
    return (
      <div className="faded">
        <h3>SUPPORTED STUDENT PLACEMENTS</h3>
        <p>
          We activate and support tertiary work integrated learning placements within commercial businesses. We take every opportunity to incorporate students into our{" "}
          <a href="https://www.taptu.com.au" rel="noopener noreferrer" target="_blank">
            Taptu
          </a>{" "}
          projects (our sister business), as well as supporting our clients to do the same.
        </p>
        <p>
          Why do we do it? It provides a great vehicle to showcase young talent, helps bridge the perceived job-readiness gap, looks fantastic on a resume, and creates long-term employment
          opportunities post-project.
        </p>
        <p id="desktop">
          Interested? <a href="#contact">Contact us</a> if you’d like to know more or continue to <a onClick={this.props.onServicesClick}>Graduates On-Demand.</a>
        </p>
        <div className="buttons">
          <button onClick={this.props.onBackClick} className="btn back" type="button">
            Back
          </button>
        </div>
      </div>
    );
  }
}

SupStuPlace.propTypes = {
  onBackClick: PropTypes.func,
  onServicesClick: PropTypes.func
};

export default SupStuPlace;
