import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pips from "../../assets/pips3.png";
import ScottHartPP from "../../assets/profile-scott.png";

class ScottHart extends React.Component {
  render() {
    return (
      <div className="faded">
        <div className="profile">
          <img src={ScottHartPP} alt="Scott Hart" width="70%" />
        </div>
        <div className="bio">
          <h2>Scott Hart</h2>
          <h3>3D Simulation & Software</h3>
          <img src={Pips} alt="StarTrek Pips" />
          <p>With experience grounded in team-based development environments and interactive digital experiences, Scott has a passion for understanding in detail how things work.</p>
          <a href="https://www.linkedin.com/in/scott-ash-hart/" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" color="#8f00dd" />
          </a>
        </div>
      </div>
    );
  }
}

export default ScottHart;
