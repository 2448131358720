import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Testimonals.css";

export default class Testimonals extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      pauseOnHover: true,
      speed: 1000,
      autoplaySpeed: 9000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider {...settings}>
        <div>
          <div className="testi">
            <p>
              Head Full of Heart hit the ground running with a smile and plenty of energy. They fit our culture and immediately added value as they took our product roadmap in their stride. Great to have them on board. Their technical capability was evident not only in software development, but also in taking the lead in our online communication strategy, and adding fresh blood to our market development. Very impressive.
            </p>
            <strong> Commercial SaaS Vendor</strong>
            <p>Adelaide</p>
          </div>
        </div>
        <div>
          <div className="testi">
            <p>
              It can be daunting entering the IT industry for the first time, but the mentoring and skills I received from Head Full of Heart has equipped and prepared me for these early industry steps. Whether you’re a student or an employer, I recommend getting in touch with the friendly team at Head Full of Heart.
            </p>
            <strong>Rachel Mesler</strong>
            <p>Bachelor of Information Technology (Digital Media)(Hons)</p>
          </div>
        </div>
        <div>
          <div className="testi">
            <p>
              Flinders University and the College of Science & Engineering have enjoyed a long-standing relationship with Head Full of Heart through our Work Integrated Learning (WIL) Program. The dedication to student and graduate development that Luca, Dennis and the team consistently provide is second to none. Opportunities to place within Head Full of Heart are always highly contested between students as they recognise the value of the experiences and the individual career development support they receive.
            </p>
            <strong>Larissa Pearce</strong>
            <p>Flinders University</p>
          </div>
        </div>
      </Slider>
    );
  }
}
