import React from "react";
import "../Geekify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeekWhatWeKnow from "./GeekWhatWeKnow";
import PeepWhatWeKnow from "./PeepWhatWeKnow";
import PropTypes from "prop-types";

function GeekifyButton(props) {
  return (
    <button id="btn-geekify" type="button" onClick={props.onClick}>
      <FontAwesomeIcon id="pp" icon={["fab", "pied-piper-hat"]} size="1x" color="var(--color-brand-green)" /> Geekify
    </button>
  );
}

function PeepButton(props) {
  return (
    <button id="btn-geekify" type="button" onClick={props.onClick}>
      <FontAwesomeIcon id="pp" icon={["fab", "pied-piper-hat"]} size="1x" color="var(--color-brand-green)" /> Peepify
    </button>
  );
}

class WhatWeKnow extends React.Component {
  constructor(props) {
    super(props);
    this.handleGeek = this.handleGeek.bind(this);
    this.handlePeep = this.handlePeep.bind(this);
    this.state = { isPeeped: true };
    this.state = { content: <PeepWhatWeKnow /> };
  }

  handleGeek() {
    this.setState({ isPeeped: true });
    this.setState({ content: <GeekWhatWeKnow /> });
  }

  handlePeep() {
    this.setState({ isPeeped: false });
    this.setState({ content: <PeepWhatWeKnow /> });
  }

  render() {
    const isPeeped = this.state.isPeeped;
    let button;

    if (isPeeped) {
      button = <PeepButton onClick={this.handlePeep} />;
    } else {
      button = <GeekifyButton onClick={this.handleGeek} />;
    }

    return (
      <div>
        <div className="about--info faded ">
          <div className="desktop">
            <h2>WHAT WE KNOW {button}</h2>
            {this.state.content}
          </div>
          <div className="mobile">{this.state.content}</div>
        </div>
      </div>
    );
  }
}

GeekifyButton.propTypes = {
  onClick: PropTypes.func
};

PeepButton.propTypes = {
  onClick: PropTypes.func
};

export default WhatWeKnow;
