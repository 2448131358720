import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pips from "../../assets/pips2.png";
import SolomonLaingPP from "../../assets/profile-solomon.jpg";

class SolomonLaing extends React.Component {
  render() {
    return (
      <div className="faded">
        <div className="profile">
          <img src={SolomonLaingPP} alt="Solomon Laing" width="70%" />
        </div>
        <div className="bio">
          <h2>Solomon Laing</h2>
          <h3>Full Stack Developer</h3>
          <img src={Pips} alt="StarTrek Pips" />
          <p>
            With a background in full stack development, Solomon is dedicated to solving problems using technology. His sharp eye and ability to learn on his feet gives him a knack at bringing
            projects to life.
          </p>
          <a href="https://www.linkedin.com/in/inkletblot/" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" color="#8f00dd" />
          </a>
        </div>
      </div>
    );
  }
}

export default SolomonLaing;
