import React from "react";
import "./Header.css";
import Logo from "../../assets/hfoh_logohorizontal.png";
import Menu from "../Hamburger/Menus";

class Header extends React.Component {
  render() {
    return (
      <div className="topnav-wrapper">
        <div className="topnav">
        <div className="desktop">
            <nav>
              <a id="navlogo" href="#home">
                <img className="logo" src={Logo} alt="Head Full of Heart" />
              </a>
              <a id="menu-btn-heart-text" href="#contact">
                CONTACT
              </a>
              <a id="menu-btn-of-text" href="#team">
                TEAM
              </a>
              <a id="menu-btn-full-text" href="#services">
                SERVICES
              </a>
              <a id="menu-btn-head-text" href="#about">
                ABOUT
              </a>
            </nav>
            </div>
            <div className="mobile">
              <a id="navlogo" href="#home">
                <img className="logo" src={Logo} alt="Head Full of Heart" />
              </a>
              <div className="right">
              <Menu/>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Header;
