import React from "react";

class WhyWeExist extends React.Component {
  render() {
    return (
      <div>
        <div className="about--info faded">
          <h2>WHY WE EXIST</h2>
          <p>We have three key objectives which drive everything we do:</p>
          <p>To secure exciting and meaningful roles for our state’s underutilised young professionals, allowing them to grow to become our next generation of experts, leaders and innovators.</p>
          <p>To energise local business with enthusiastic, creative young professionals. This enables businesses to build new capabilities, disrupt markets rather than be disrupted, integrate new ideas, creativity and passion into their workforce, and do so in a commercially safe and supported way.</p>
          <p>To build the capability and capacity of our state, making us more inclusive, diverse and competitive, whilst retaining more local talent and building a stronger long-term presence on the global stage.</p>
        </div>
      </div>
    );
  }
}

export default WhyWeExist;
