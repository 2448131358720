import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pips from "../../assets/pips3.png";
import DaisyKeatingPP from "../../assets/profile-daisy.jpg";

class DaisyKeating extends React.Component {
  render() {
    return (
      <div className="faded">
        <div className="profile">
          <img src={DaisyKeatingPP} alt="Daisy Keating" width="70%" />
        </div>
        <div className="bio">
          <h2>Daisy Keating</h2>
          <h3>Education & Career Development</h3>
          <img src={Pips} alt="StarTrek Pips" />
          <p>
            {
              "Originally from an education background, Daisy has transferred her strengths of communication and organisation to the IT context. She believes in genuine connections and prides herself on fostering them in the IT community. Daisy truly cares for professional growth and development highlighting her passion for learning, connecting students to Industry and empowering others."
            }
          </p>
          <a href="https://www.linkedin.com/in/daisy-keating-621972271" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" color="#8f00dd" />
          </a>
        </div>
      </div>
    );
  }
}

export default DaisyKeating;
