import React, { Component } from "react";
import "core-js/fn/array/find";
import "core-js/fn/array/includes";
import "core-js/fn/number/is-nan";

import "./HfohWebsiteApp.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Content from "./components/ContentWindow";
import FullpageWrapper from "./components/FullPage";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPaperPlane, faQuoteLeft, faChevronDown, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faPiedPiperHat } from "@fortawesome/free-brands-svg-icons";
import PrivacyPolicy from "./components/PrivacyPolicy";

library.add(faLinkedin, faPaperPlane, faPiedPiperHat, faQuoteLeft, faChevronDown, faUserGraduate);

const fullpageOptions = {
  anchors: ["home", "about", "services", "team", "contact"],
  verticalCentered: false,
  licenseKey: "339ED669-CA20456F-BB9A705A-B1EF56A2",
  paddingTop: "129px",
  paddingBottom: "64px",
  fitToSection: true,
  responsiveWidth: 999,
  responsiveHeight: 768,
  lockAnchors: false,

  onLeave: (origin, destination, direction) => {
    // Manage Google Analytics, if loaded
    if (window.ga) {
      if (destination.index === 0) {
        window.ga("set", "page", "/#home");
        window.ga("send", "pageview");
      } else if (destination.index === 1) {
        window.ga("set", "page", "/#about");
        window.ga("send", "pageview");
      } else if (destination.index === 2) {
        window.ga("set", "page", "/#services");
        window.ga("send", "pageview");
      } else if (destination.index === 3) {
        window.ga("set", "page", "/#team");
        window.ga("send", "pageview");
      } else if (destination.index === 4) {
        window.ga("set", "page", "/#contact");
        window.ga("send", "pageview");
      }
    }

    // Handle fade in / out of HFoH nav logo when leaving #home
    if (origin.index === 0 && direction === "down") {
      document.getElementById("navlogo").className = "fadeIn";
    } else if (origin.index === 1 && direction === "up") {
      document.getElementById("navlogo").className = "fadeOut";
    }
  }
};

class HfohWebsiteApp extends Component {
  render() {
    return (
      <div className="HfohWebsiteApp">
        <div id="background" />
        <input type="checkbox" id="privacypolicybuttons" />
        <Header />

        <div className="maincontentblock">
          <Content>
            <FullpageWrapper {...fullpageOptions} />
          </Content>
        </div>

        <div className="privacypolicyblock">
          <PrivacyPolicy />
        </div>

        <Footer />
      </div>
    );
  }
}
export default HfohWebsiteApp;
