import React from "react";
import "../Geekify.css";

class WhatWeKnow extends React.Component {
  render() {
    return (
      <div className="faded" id="geek">
        <div className="desktop">
          <div className="moderate leftpad">
            <h3>CLOUD</h3>
            <p>
              Get your head in the clouds. By leveraging cloud platforms, we can help minimise your infrastructure overheads, whilst maximising your service. Move to SaaS/PaaS/IaaS for speedy service
              and management without all the fuss.
            </p>
            <h3>INTEGRATION</h3>
            <p>Disparate cloud solutions have you feeling down? Why not link them up? We can help automate your workflow, or provide essential data connections between different services.</p>
            <h3>SOFTWARE</h3>
            <p>We use emergent web technologies to produce interactive user experiences, web applications, and serve static content. Let us help you realise your software dream.</p>
          </div>
          <div className="moderate right">
            <h3>DATA AND ANALYTICS</h3>
            <p>
              Give us your tired, your poor, your huddled masses of data… and let us create data-driven solutions, allowing you to make sense of it all and drive stronger insight-based decision
              making.
            </p>
            <h3>SECURITY</h3>
            <p>
              Is your software security an afterthought? Get proactive and incorporate blue-team defensible software design and development principles into your agile software lifecycle instead. Your
              investors will love you for it.
            </p>
            <h3>REALITY 2.0</h3>
            <p>
              Stop thinking of AR and VR gimmicks. Instead seek solutions which seamlessly blend traditional software into reality, making technology a pervasive and integral part of how we function
              and experience the world.
            </p>
          </div>
        </div>

        <div className="mobile">
          <p />
          <h3>CLOUD</h3>
          <p>
            Get your head in the clouds. By leveraging cloud platforms, we can help minimise your infrastructure overheads, whilst maximising your service. Move to SaaS/PaaS/IaaS for speedy service
            and management without all the fuss.
          </p>
          <h3>INTEGRATION</h3>
          <p>Disparate cloud solutions have you feeling down? Why not link them up? We can help automate your workflow, or provide essential data connections between different services.</p>
          <h3>SOFTWARE</h3>
          <p>We use emergent web technologies to produce interactive user experiences, web applications, and serve static content. Let us help you realise your software dream.</p>
          <h3>DATA AND ANALYTICS</h3>
          <p>
            Give us your tired, your poor, your huddled masses of data… and let us create data-driven solutions, allowing you to make sense of it all and drive stronger insight-based decision making.
          </p>
          <h3>SECURITY</h3>
          <p>
            Is your software security an afterthought? Get proactive and incorporate blue-team defensible software design and development principles into your agile software lifecycle instead. Your
            investors will love you for it.
          </p>
          <h3>REALITY 2.0</h3>
          <p>
            Stop thinking of AR and VR gimmicks. Instead seek solutions which seamlessly blend traditional software into reality, making technology a pervasive and integral part of how we function and
            how we experience the world.
          </p>
        </div>
      </div>
    );
  }
}

export default WhatWeKnow;
