import React, { Component } from "react";
import Button from "../shared/components/Button";
import logo from "./../logo.svg";
import "./TaptuWebsiteApp.css";

class TaptuWebsiteApp extends Component {
  render() {
    return (
      <div className="TaptuWebsiteApp">
        <header className="TaptuWebsiteApp-header">
          <img src={logo} className="TaptuWebsiteApp-logo" alt="logo" />
          <h1 className="TaptuWebsiteApp-title">Taptu Website</h1>
        </header>
        <p className="TaptuWebsiteApp-intro">
          To get started, edit <code>src/App.js</code> and save to reload.
        </p>
        <Button name={"click me"} />
      </div>
    );
  }
}

export default TaptuWebsiteApp;
