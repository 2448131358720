import React from "react";
import "./Menus.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function close() {
  var uncheck = document.getElementsByTagName("input");
  for (var i = 0; i < uncheck.length; i++) {
    if (uncheck[i].type === "checkbox") {
      uncheck[i].checked = false;
    }
  }
}

class Hams extends React.Component {
  render() {
    return (
      <nav>
        <div className="sidemenu" id="menuToggle">
          <input type="checkbox" />

          <span />
          <span />
          <span />

          <ul id="menu">
            <a href="#home" onClick={() => close()}>
              <li id="menu-btn-home-text">HOME</li>
            </a>
            <a href="#about" onClick={() => close()}>
              <li id="menu-btn-head-text">ABOUT</li>
            </a>
            <a href="#services" onClick={() => close()}>
              <li id="menu-btn-full-text">SERVICES</li>
            </a>
            <a href="#team" onClick={() => close()}>
              <li id="menu-btn-of-text">TEAM</li>
            </a>
            <a href="#contact" onClick={() => close()}>
              <li id="menu-btn-heart-text">CONTACT</li>
            </a>

            <div className="soicals">
              <a className="soicalicon" href="https://www.linkedin.com/company/head-full-of-heart" rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon icon={["fab", "linkedin"]} size="lg" color="var(--color-brand-charcoal)" />
              </a>
              <a className="soicalicon" href="mailto:hello@hfoh.com.au">
                <FontAwesomeIcon icon="paper-plane" size="lg" color="var(--color-brand-charcoal)" />
              </a>
            </div>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Hams;
