import React from "react";
import "./About.css";
import WhyItWorks from "./WhyItWorks";
import WhatWeKnow from "./WhatWeKnow";
import HowWeDoIt from "./HowWeDoIt";
import WhyWeExist from "./WhyWeExist";
import WhyOurBusiness from "./WhyOurBusiness";
import Collapsible from "react-collapsible";

class About extends React.Component {
  constructor() {
    super();
    this.state = { content: <WhyWeExist /> };
  }

  handleClick(contentType) {
    switch (contentType) {
      case "WhyWeExist":
        this.setState({ content: <WhyWeExist /> });
        break;
      case "WhyOurBusiness":
        this.setState({ content: <WhyOurBusiness /> });
        break;
      case "WhyItWorks":
        this.setState({ content: <WhyItWorks /> });
        break;
      case "HowWeDoIt":
        this.setState({ content: <HowWeDoIt /> });
        break;
      case "WhatWeKnow":
        this.setState({ content: <WhatWeKnow /> });
        break;
      default:
        this.setState({ content: <WhyWeExist /> });
        break;
    }
  }

  render() {
    return (
      <div className="about">
        <div className="title">
          <a className="desktop" onClick={() => this.handleClick("WhyWeExist")}>
            <h1>ABOUT</h1>
          </a>
          <h1 className="mobile">ABOUT</h1>
        </div>
        <div className="main">
          <div className="desktop">
            <div className="narrow left">
              <ul>
                <a onClick={() => this.handleClick("WhyWeExist")}>
                  <h2>WHY WE EXIST</h2>
                </a>
                <a onClick={() => this.handleClick("WhyOurBusiness")}>
                  <h2>WHY OUR BUSINESS</h2>
                </a>
                <a onClick={() => this.handleClick("WhyItWorks")}>
                  <h2>WHY IT WORKS</h2>
                </a>
                <a onClick={() => this.handleClick("HowWeDoIt")}>
                  <h2>HOW WE DO IT</h2>
                </a>
                <a onClick={() => this.handleClick("WhatWeKnow")}>
                  <h2>WHAT WE KNOW</h2>
                </a>
              </ul>
            </div>
            <div className="broad right desktop">{this.state.content}</div>
          </div>
          <div className="mobile">
            <WhyWeExist />
            <Collapsible trigger="WHY OUR BUSINESS">
              <WhyOurBusiness />
            </Collapsible>
            <Collapsible trigger="WHY IT WORKS">
              <WhyItWorks />
            </Collapsible>
            <Collapsible trigger="HOW WE DO IT">
              <HowWeDoIt />
            </Collapsible>
            <Collapsible trigger="WHAT WE KNOW">
              <WhatWeKnow />
            </Collapsible>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
