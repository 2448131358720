import React from "react";
import BusinessClients from "./BusinessClients";
import TertiaryClients from "./TertiaryClients";
import PropTypes from "prop-types";

class Offer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: <div className="faded" />
    };
  }

  handleClick(contentHighlight) {
    switch (contentHighlight) {
      case "YoungProfessionals":
        document.getElementById("MenDev").className = "hexPur hexGreen";
        document.getElementById("SupStuPlace").className = "hexPur hexGreen";
        document.getElementById("GradSecond").className = "hexPur hexGreen";
        document.getElementById("GradOn").className = "hexPur hexGreen";
        document.getElementById("ProgAdvis").className = "hexPur halfopacity";
        document.getElementById("ManGradProg").className = "hexPur halfopacity";
        document.getElementById("clientlist").className = "show";
        document.getElementById("btnedu").className = "btn education";
        document.getElementById("btnbus").className = "btn business";
        document.getElementById("btnstu").className = "btn students studentsclicked";
        this.setState({ clients: <div className="faded" /> });
        break;
      case "Business":
        document.getElementById("ManGradProg").className = "hexPur hexPurple";
        document.getElementById("GradSecond").className = "hexPur hexPurple";
        document.getElementById("GradOn").className = "hexPur hexPurple";
        document.getElementById("SupStuPlace").className = "hexPur halfopacity";
        document.getElementById("ProgAdvis").className = "hexPur hexPurple";
        document.getElementById("MenDev").className = "hexPur hexPurple";
        document.getElementById("clientlist").className = "show";
        document.getElementById("btnedu").className = "btn education";
        document.getElementById("btnstu").className = "btn students";
        document.getElementById("btnbus").className = "btn business businessclicked";
        this.setState({ clients: <BusinessClients /> });
        break;
      case "TertiarySector":
        document.getElementById("ProgAdvis").className = "hexPur hexRed";
        document.getElementById("ManGradProg").className = "hexPur halfopacity";
        document.getElementById("GradSecond").className = "hexPur halfopacity";
        document.getElementById("GradOn").className = "hexPur halfopacity";
        document.getElementById("SupStuPlace").className = "hexPur hexRed";
        document.getElementById("MenDev").className = "hexPur hexRed";
        document.getElementById("clientlist").className = "show";
        document.getElementById("btnbus").className = "btn business";
        document.getElementById("btnstu").className = "btn students";
        document.getElementById("btnedu").className = "btn education educationclicked";
        this.setState({ clients: <TertiaryClients /> });
        break;
      default:
        document.getElementById("ProgAdvis").className = "hexPur";
        document.getElementById("ManGradProg").className = "hexPur";
        document.getElementById("GradSecond").className = "hexPur";
        document.getElementById("GradOn").className = "hexPur";
        document.getElementById("SupStuPlace").className = "hexPur";
        document.getElementById("MenDev").className = "hexPur";
        document.getElementById("btnedu").className = "btn education";
        document.getElementById("btnbus").className = "btn business";
        document.getElementById("btnstu").className = "btn students";
        this.setState({ clients: <div className="faded" /> });
        break;
    }
  }

  render() {
    return (
      <div className="faded">
        <p>
          We offer six discrete services, each targeting specific needs. In doing so we hope to take businesses on a gradual journey; starting with on-demand services, growing through secondments and
          managed intakes, until they eventually become champions of young professional recruitment themselves. This has the potential to create future mentors, completing and reinforcing the positive
          cycle.
        </p>
        <p className="exploremap" id="desktop">
          <a onClick={this.props.onServicesClick}>Explore our services...</a>
        </p>
        <div className="buttons">
          <button onClick={() => this.handleClick("YoungProfessionals")} className="btn students" type="button" id="btnstu">
            Young Professionals
          </button>
          <button onClick={() => this.handleClick("Business")} className="btn business" type="button" id="btnbus">
            Business
          </button>
          <button onClick={() => this.handleClick("TertiarySector")} className="btn education" type="button" id="btnedu">
            Tertiary Sector
          </button>
        </div>
        <div id="clientlist">{this.state.clients}</div>
      </div>
    );
  }
}

Offer.propTypes = {
  onServicesClick: PropTypes.func
};

export default Offer;
