import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function GAPrivacy() {
  // Google analytics JS may be blocked by adblocker - handle this gracefully
  if (window.ga) {
    window.ga("set", "page", "/#privacypolicy");
    window.ga("send", "pageview");
    window.scrollTo(0, 0);
  }
}

class Contact extends React.Component {
  render() {
    return (
      <div className="contact">
        <div className="title">
          <h1>CONTACT</h1>
        </div>
        <div className="main">
          <div className="desktop">
            <div className="broad left desktop">
              <h2 className="desktop">CONNECT</h2>
              <p className="desktop">
                No one likes directed selling, us included. Our primary goal is to do social good for people and businesses throughout Adelaide. We’d prefer you approach us with your current
                challenges, when you are ready, on your terms. We will listen and discuss your needs over an informal chat, then give you honest advice on how we can help.
              </p>
              <p className="desktop">
                If you’re curious, get in <a href="mailto:hello@hfoh.com.au?subject=I'd like to know more about Head Full of Heart"> contact </a> with us. You won’t be disappointed.
              </p>
              <div className="broad left">
                <div id="map-container" />
              </div>
              <div className="narrow right address desktop">
                <p className="desktop">
                  <strong>Head Full of Heart </strong>
                </p>
                <p>
                  {" "}
                  (By{" "}
                  <a href="https://www.taptu.com.au" rel="noopener noreferrer" target="_blank">
                    Taptu
                  </a>{" "}
                  Pty. Ltd.){" "}
                </p>
                <br />
                <p>Level 1</p>
                <p>33 King William Street</p>
                <p>Adelaide, SA 5000</p>
                <p>
                  <i>(by appointment only)</i>
                </p>
                <br />
                <p>ABN 71 608 557 151 </p>
                <br />
                <div className="broad left">
                  <label className="privacypolicybutton" htmlFor="privacypolicybuttons" onClick={GAPrivacy}>
                    Privacy Policy
                  </label>
                </div>
              </div>
            </div>
            <div className="narrow right"></div>
          </div>
          <div className="mobile">
            <h2>CONNECT</h2>
            <p>
              No one likes directed selling, us included. Our primary goal is to do social good for people and businesses throughout Adelaide. We’d prefer you approach us with your current challenges,
              when you are ready, on your terms. We will listen and discuss your needs over an informal chat, then give you honest advice on how we can help.
            </p>
            <p>
              If you’re curious, get in <a href="mailto:hello@hfoh.com.au?subject=I'd like to know more about Head Full of Heart"> contact </a> with us. You won’t be disappointed.
            </p>

            <div className="socialicons mobile">
              <center>
                <a href="https://www.linkedin.com/company/head-full-of-heart" rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon icon={["fab", "linkedin"]} size="3x" color="var(--color-brand-red)" />
                </a>
                <a href="mailto:hello@hfoh.com.au?subject=I'd like to know more about Head Full of Heart">
                  <FontAwesomeIcon icon="paper-plane" size="3x" color="var(--color-brand-red)" />
                </a>
              </center>
            </div>
            <center>
              <p>
                Head Full of Heart ( By{" "}
                <a href="https://www.taptu.com.au" rel="noopener noreferrer" target="_blank">
                  Taptu
                </a>{" "}
                Pty. Ltd. )<br />
                ABN 71 608 557 151
                <br />
                Level 1, 33 King William St, Adelaide 5000
              </p>
              <label className="privacypolicybutton" htmlFor="privacypolicybuttons" onClick={GAPrivacy}>
                Privacy Policy
              </label>
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
