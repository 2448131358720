import React from "react";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className="privacypolicy">
          <h1>PRIVACY POLICY</h1>
          <p>Your privacy is important to us and we are committed to complying with the Australian Privacy Principles and the Privacy Act 1988 (ACT).</p>
          <p>This policy describes how we collect, use, and store your personal information, and how you can access what we have collected.</p>
          <p>
            We may collect personal information to allow us to provide services to you. The information that we may collect will depend on what services we provide to you and may include name, address,
            email address, when and how you use our website and phone numbers.
          </p>
          <p>
            We will only use or disclose your personal information to provide our services to you, market our services to you, conduct our business of IT consulting and advisory services, or to offer
            publications or seminars or functions that we think you may be interested in. We may also use or disclose your personal information for other purposes that you may have specifically
            consented to, or where otherwise allowed or required by law. Please let us know if you do not wish for us to use your information in that way.
          </p>
          <p>We will endeavour to keep the information collected from you up to date, but if there are changes please let us know.</p>
          <p>
            We will take all reasonable steps to ensure your personal information is securely stored and protected from misuse, unauthorised access or disclosure through restricted access and electronic
            security measures.
          </p>
          <p>
            If you wish to access your personal information or correct such information or to let us know about possible issues you have with our policy, please send an email to
            <a href="mailto:hello@hfoh.com.au"> hello@hfoh.com.au</a>
          </p>
          <p>We may vary this policy from time to time by posting it on this website.</p>
        <div className="privacypolicybuttonclose">
          <label className="privacypolicybutton" htmlFor="privacypolicybuttons" onClick={ () => window.fullpage_api.moveTo(1,0)}>Close</label>

      </div>
      </div>
    );
  }
}

export default PrivacyPolicy;